import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BottomHeroComponent from "../components/herocomponents/bottomherocomponent"
import Maximizing from "../components/bodycomponents/maximizing"
import RED_BG from "../images/background-images/bg-red.svg"
import M_GRAY_BG from "../images/background-images/bg-medium-gray.svg"
import { colors } from "../utils/colors"
import GRAY_ARROW_BG from "../images/SSLLC_Arrow_Movement.svg"
import FormComponent from "../components/bodycomponents/form-component"
import FAQ from "../components/faq-components/faq"
import { sellEquipmentFaqItems } from "../utils/data/faq"
import TECHNICIAN from "../images/SSLLC-Technician-600x900.jpg"
import APPRAISAL from "../images/SSLLC-Appraisal-Services-1-600x400.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTruckRampBox,
  faEarthAmericas,
  faHandHoldingDollar,
} from "@fortawesome/free-solid-svg-icons"

const SellEquipment = () => (
  <Layout className="main inner-page-with-banner">
    <SEO
      title={`Free Equipment Assessment for Quick Resale Opportunities`}
      description={`Turn surplus equipment into cash with Surplus Solutions. We offer appraisals, logistics, and tailored solutions for selling your equipment.`}
    />
    <BottomHeroComponent
      backgroundImage={RED_BG}
      backgroundColor={colors.scarletRed}
      fontShadowColor={colors.red}
      shadowColor="shadow-red-top"
      pageTitle={<span id="sell-equipment-form">Sell Equipment</span>}
      title="Get a Free Equipment Assessment"
      body={
        <div className="pt-4">
          <div className="flex flex-col md:flex-row justify-between gap-x-6 gap-y-4">
            <div className="w-full md:w-1/2">
              <FormComponent formType="Equipment Sourcing"/>
            </div>

            <div className="w-full md:w-1/2 text-left">
              <p>
                Surplus Solutions can help you find the hidden value in unused
                or outdated equipment. It’s easy to overlook—which is why,
                whether you’re upgrading, downsizing, or simply reevaluating
                your needs, a free equipment assessment is a great way to reveal
                the true worth of your idle assets.
              </p>
              <br />
              <p>
                Our AMEA-certified team carefully analyzes your equipment and
                provides a comprehensive resale report to maximize your unused
                equipment’s value.
              </p>
              <br />
              <p>
                Don’t let valuable assets gather dust. Find out what your unused
                equipment is worth today!
              </p>
            </div>
          </div>
        </div>
      }
    />
    {/* Body */}
    <div className="shadow-custom-out">
      <div className="container max-w-screen-xl py-14">
        <div className="w-full flex flex-col-reverse md:flex-row gap-x-10 gap-y-8 pb-5">
          <div className="w-full md:w-3/4">
            <h2 className="text-balance font-bold">
              We Know Your Equipments’ True Value
            </h2>
            <p className="pb-5">
              With over 25 years of market expertise, Surplus Solutions is
              uniquely positioned to maximize your equipment’s value without
              complications or time wasted. We specialize in buying and selling
              used lab, processing, and packaging equipment, sourced from
              leading manufacturers including Agilent, Waters, Thermo Fisher
              Scientific, GEA, Cytiva, Sartorius, Beckman Coulter, and more.
            </p>
            <p>
              When you’re ready to sell your surplus or idle equipment, we offer
              flexible solutions tailored to your exact needs. Whether through
              brokerage, consignment, instant cash purchases, or a combination
              of channels, we guide you every step of the way to ensure you get
              the maximum value for your assets. Our team handles every step,
              from appraisal to logistics, so you can maximize your equipment’s
              value without lifting a finger.
            </p>
          </div>
          <div className="w-full md:w-1/4">
            <img
              src={TECHNICIAN}
              alt="Technician performing a detailed assessment of lab equipment for resale potential in a warehouse setting."
              className="rounded-[5px]"
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row gap-x-10 gap-y-8 pt-5">
          <div className="w-full md:w-1/3">
            <img
              src={APPRAISAL}
              alt="Young happy female worker in a lab writing notes about appraisal of equipment."
              className="rounded-[5px]"
            />
          </div>
          <div className="w-full md:w-2/3">
            <h2 className="text-balance font-bold">
              Maximize Value & Sustainability with Precision Assessment
            </h2>
            <p>
              Our highly trained technicians carefully assess each piece of lab
              equipment to determine its resale potential. With years of
              experience in the industry, our team knows exactly what buyers are
              looking for and how much your equipment is worth. Not only will
              you unlock the hidden value of your assets, but you’ll also
              contribute to a greener, more sustainable industry. With a focus
              on both sustainability and profitability, our service ensures that
              your unused lab assets don’t go to waste.
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* Process for Buying and Selling */}
    <div className="bg-light-gray shadow-custom-in">
      <div className="container max-w-screen-xl py-14">
        <h2 className="text-red text-balance font-bold py-2">
          The Surplus Solutions Process for Buying & Selling Equipment
        </h2>
        <div className="w-full flex flex-col md:flex-row gap-x-10 pb-6">
          <div className="w-full md:w-1/2 pb-5">
            <h3>Onsite Inspection</h3>
            <p className="pb-2">Our certified equipment specialists:</p>
            <ul className="list-disc list-outside pl-5">
              <li>Inspect and photograph your surplus assets</li>
              <li>Provide detailed specification reports</li>
              <li>Save you time by handling all assessments onsite</li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 pb-5">
            <h3>Upfront Pricing</h3>
            <p>
              Based on that thorough inspection, we can make upfront cash offers
              or provide consignment/brokerage pricing tailored to your chosen
              disposition method. For equipment that’s sold ‘as is’, you won’t
              incur any storage or maintenance fees.
            </p>
          </div>
        </div>
        <h3 className="pb-5">Multiple Sourcing Channels</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Card One */}
          <div className="bg-white rounded-[5px]">
            <div className="w-full flex flex-col justify-between">
              <div className="p-8 text-left flex flex-col justify-between relative z-20 h-full">
                <div className="flex items-center space-x-5">
                  <span>
                    <FontAwesomeIcon
                      size="2xl"
                      icon={faEarthAmericas}
                      className="mb-4 text-scarlet-red"
                    />
                  </span>
                  <h5 className="text-[24px] pb-2 text-balance">Brokerage</h5>
                </div>
                <div className="flex-grow flex text-pretty">
                  <span>
                    <p>
                      Our deep industry knowledge and extensive network allow us
                      to provide up-to-date market insights, ensuring maximum
                      returns on your equipment. Your equipment will stay on
                      your property and we will sell it on your behalf. The most
                      seamless option we offer.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Card Two */}
          <div className="bg-white rounded-[5px]">
            <div className="w-full flex flex-col justify-between">
              <div className="p-8 text-left flex flex-col justify-between relative z-20 h-full">
                <div className="flex items-center space-x-5">
                  <span>
                    <FontAwesomeIcon
                      size="2xl"
                      icon={faTruckRampBox}
                      className="mb-4 text-scarlet-red"
                    />
                  </span>
                  <h5 className="text-[24px] pb-2 text-balance">Consignment</h5>
                </div>
                <div className="flex-grow flex text-pretty">
                  <span>
                    <p>
                      We can securely store and sell your equipment to free up
                      space in your facility, maintain full trackability and
                      enjoy hands-off management.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Card Three */}
          <div className="bg-white rounded-[5px]">
            <div className="w-full flex flex-col justify-between">
              <div className="p-8 text-left flex flex-col justify-between relative z-20 h-full">
                <div className="flex items-center space-x-5">
                  <span>
                    <FontAwesomeIcon
                      size="2xl"
                      icon={faHandHoldingDollar}
                      className="mb-4 text-scarlet-red"
                    />
                  </span>
                  <h5 className="text-[24px] pb-2 text-balance">
                    Outright Purchase
                  </h5>
                </div>
                <div className="flex-grow flex text-pretty">
                  <span>
                    <p>
                      We purchase single pieces or entire facilities outright,
                      with hassle-free transactions and all-inclusive logistics
                      coordination to ensure quick sales.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="pt-10">Return on Investment</h3>
        <p>
          No matter which sales method you choose, Surplus Solutions takes care
          of all upfront logistics and inventory costs—you pay nothing
          whatsoever. With brokerage and consignment, we market your equipment
          immediately through the most effective channels. If you choose
          outright purchase, you will receive payment within 7 days of pickup.
        </p>
      </div>
    </div>
    <FAQ
      title="Sell Equipment FAQs"
      items={sellEquipmentFaqItems}
      backgroundColor={colors.lightGray}
      backgroundImage={M_GRAY_BG}
      shadowColor="shadow-mediumGray-top"
    />
    <Maximizing
      backgroundImage={GRAY_ARROW_BG}
      title="Start Maximizing Value Today"
      body={
        <p>
          The best place to begin? Right here. Just fill out the brief form at
          the top of this page, and our experts will get to work figuring out
          the best ways to sell your surplus lab equipment.
        </p>
      }
      to="#sell-equipment-form"
      buttonTitle="Start Your Assessment"
      buttonColor={colors.red}
      hoverColor={colors.blue}
    />
  </Layout>
)

export default SellEquipment
