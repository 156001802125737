import React from "react"

export default function BottomHeroComponent(props) {
  return (
    <>
      <div
        className="bg-cover"
        style={{
          backgroundColor: props.backgroundColor,
          backgroundImage: `URL(${props.backgroundImage})`,
        }}
      >
        <div className="container max-w-screen-xl w-full text-white items-center pt-10 lg:pt-14">
          <div
            className={`bg-white w-full rounded-t-[15px] px-10 py-[1.875rem] mt-[15px] text-center ${props.shadowColor}`}
          >
            <span className="text-[1.5rem] font-semibold text-medium-gray pb-2">
              {props.pageTitle}
            </span>
            <h1
              className="text-balance mt-0 mb-4"
              style={{
                color: props.fontShadowColor,
              }}
            >
              {props.title}
            </h1>
            <span className="text-charcoal text-pretty">{props.body}</span>
          </div>
        </div>
      </div>
    </>
  )
}
