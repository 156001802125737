import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

export default function FormComponent({ formType, productDetails }) {
  const hubspotForms = {
    Contact: {
      portalId: "44305279",
      formId: "21b6cf02-622a-44ef-9b4d-dccad4702ed5",
      sfdcCampaignId: "701Qh00000MieRiIAJ",
    },

    "Auction Support": {
      portalId: "44305279",
      formId: "d36e85a8-ad79-4a67-b7e0-0e5d1d8b77a7",
      sfdcCampaignId: "701Qh00000MjIvPIAV",
    },

    "Equipment Sourcing": {
      portalId: "44305279",
      formId: "51912e46-17d1-4b49-b2e8-939a416687c1",
      sfdcCampaignId: "701Qh00000MjCwUIAV",
    },

    "Request a Quote": {
      portalId: "44305279",
      formId: "ae138aca-f33e-4640-ba6f-2f39bf7b9b3d",
      sfdcCampaignId: "701Qh00000MjDKcIAN",
    },

    Refurbishment: {
      portalId: "44305279",
      formId: "65835786-2575-4079-8989-3bba14a7a0f3",
      sfdcCampaignId: "701Qh00000Mj5etIAB",
    },

    "Marketing Sign Up": {
      portalId: "44305279",
      formId: "ca859765-38e9-416a-90f1-af27d68a0838",
      sfdcCampaignId: "701Qh00000MjJEkIAN",
    },

    "Auction Alerts": {
      portalId: "44305279",
      formId: "cd57460b-ea4d-40cb-9c19-f06eb32d8890",
      sfdcCampaignId: "701Qh00000MjKxBIAV",
    },

    // New product-specific form
    "Product Inquiry": {
      portalId: "44305279",
      formId: "ae138aca-f33e-4640-ba6f-2f39bf7b9b3d",
      sfdcCampaignId: "701Qh00000MjDKcIAN",
      requiresProductDetails: true, // Flag to check if onFormReady is needed
      //Testing
      // portalId: "44305279",
      // formId: "24200c47-27e4-495e-b58c-f17d5388513f",
      // sfdcCampaignId: "701Qh00000MjDKcIAN",
    },
  }

  useEffect(() => {
    const loadHubspotForm = () => {
      if (window.hbspt && hubspotForms[formType]) {
        const formConfig = {
          portalId: hubspotForms[formType].portalId,
          formId: hubspotForms[formType].formId,
          sfdcCampaignId: hubspotForms[formType].sfdcCampaignId,
          target: "#hubspot-form",
        }

        if (hubspotForms[formType].requiresProductDetails && productDetails) {
          formConfig.onFormReady = ($form) => {
            // Set the hidden field value
            $form
              .find("input[name='product_code__c']")
              .val(productDetails.code || "")

            // Remove "Refurbished" option if not available
            if (!productDetails.refurbishmentAvailable) {
              const refurbished = $form.find(
                "input[name='quote_request_product_condition'][value='Refurbished']"
              )
              refurbished.closest("label").remove()
            }
            // Remove "Refurbished" option if not available
            if (!productDetails.testingAvailable) {
              const certified = $form.find(
                "input[name='quote_request_product_condition'][value='Certified']"
              )
              certified.closest("label").remove()
              $form.find(".hs_quote_request_product_condition").hide()
            }

            // Reference all condition inputs
            const $conditionInputs = $form.find(
              "input[name='quote_request_product_condition']"
            )

            // Uncheck all by default
            $conditionInputs.prop("checked", false)

            // Determine which option to check
            let optionToCheck

            if (productDetails.refurbishmentAvailable) {
              optionToCheck = $conditionInputs.filter("[value='Refurbished']")
            } else if (productDetails.testingAvailable) {
              optionToCheck = $conditionInputs.filter("[value='Certified']")
            } else {
              optionToCheck = $conditionInputs.filter("[value='As-Is']")
            }

            // Check the desired option and trigger change so validation detects it
            if (optionToCheck?.length) {
              optionToCheck.prop("checked", true).trigger("change")
            }
          }
        }

        window.hbspt.forms.create(formConfig)
      }
    }

    if (!window.hbspt) {
      const script = document.createElement("script")
      script.src = "//js.hsforms.net/forms/embed/v2.js"
      script.async = true
      script.onload = loadHubspotForm
      document.body.appendChild(script)
    } else {
      loadHubspotForm()
    }
  }, [formType, productDetails])

  return (
    <div>
      <Helmet>
        <script
          type="text/javascript"
          src="//js.hsforms.net/forms/embed/v2.js"
        ></script>
      </Helmet>

      {hubspotForms[formType] ? (
        <div id="hubspot-form" className="w-full"></div>
      ) : (
        <p className="text-charcoal">
          Invalid form type provided. Please check the form type.
        </p>
      )}
    </div>
  )
}
